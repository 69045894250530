.kandidti-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.kandidti-breadcumbs {
  flex: 0 0 auto;
  width: 100%;
  height: 25px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.kandidti-navlink {
  display: contents;
}
.kandidti-icon {
  fill: var(--dl-color-gray-500);
  width: 12px;
  height: 12px;
  text-decoration: none;
}
.kandidti-icon2 {
  fill: var(--dl-color-gray-500);
  width: 12px;
  height: 12px;
}
.kandidti-navlink1 {
  color: var(--dl-color-gray-500);
  font-size: 12px;
  text-decoration: none;
}
.kandidti-text2 {
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
