.program-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.program-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  background: linear-gradient(0deg, rgba(36,77,151,1) 0%, rgba(237,25,46,1) 100%);;
  align-items: center;
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.program-container02 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.program-text {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-twounits);
}
.program-container03 {
  flex: 0 0 auto;
  color: var(--dl-color-gray-black);
  width: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
.program-container04 {
  flex: 0 0 auto;
  width: 500px;
  height: auto;
  margin: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.program-text001 {
  color: #3668bc;
  text-align: center;
}
.program-ul {
  list-style-type: disc;
  list-style-position: outside;
}
.program-li {
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-li01 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text005 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.program-li02 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text009 {
  margin-bottom: 0px;
}
.program-li03 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text012 {
  margin-bottom: 0px;
}
.program-container05 {
  flex: 0 0 auto;
  width: 500px;
  height: auto;
  margin: var(--dl-space-space-twounits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.program-text018 {
  color: rgb(54, 104, 188);
  text-align: center;
}
.program-ul01 {
  list-style-type: disc;
  list-style-position: outside;
}
.program-li04 {
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-li05 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text024 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.program-li06 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text027 {
  margin-bottom: 0px;
}
.program-container06 {
  flex: 0 0 auto;
  width: 500px;
  height: auto;
  margin: var(--dl-space-space-twounits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.program-text032 {
  color: rgb(54, 104, 188);
  text-align: center;
}
.program-ul02 {
  list-style-type: disc;
  list-style-position: outside;
}
.program-li07 {
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-li08 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text042 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.program-li09 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text045 {
  margin-bottom: 0px;
}
.program-li10 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text048 {
  margin-bottom: 0px;
}
.program-li11 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text051 {
  margin-bottom: 0px;
}
.program-container07 {
  flex: 0 0 auto;
  width: 500px;
  height: auto;
  margin: var(--dl-space-space-twounits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.program-text054 {
  color: rgb(54, 104, 188);
  text-align: center;
}
.program-ul03 {
  list-style-type: disc;
  list-style-position: outside;
}
.program-li12 {
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-li13 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text062 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.program-li14 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text065 {
  margin-bottom: 0px;
}
.program-li15 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text069 {
  margin-bottom: 0px;
}
.program-li16 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text073 {
  margin-bottom: 0px;
}
.program-container08 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-twounits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.program-text076 {
  color: rgb(54, 104, 188);
  text-align: center;
}
.program-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  justify-content: center;
}
.program-container10 {
  flex: 0 0 auto;
  width: 330px;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-right: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.program-text077 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.program-ul04 {
  list-style-type: disc;
  list-style-position: outside;
}
.program-li17 {
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-li18 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text079 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.program-li19 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text080 {
  margin-bottom: 0px;
}
.program-li20 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text081 {
  margin-bottom: 0px;
}
.program-li21 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text082 {
  margin-bottom: 0px;
}
.program-container11 {
  flex: 0 0 auto;
  width: 330px;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-right: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.program-trpisov {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.program-ul05 {
  list-style-type: disc;
  list-style-position: outside;
}
.program-li22 {
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-li23 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text084 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.program-kunci {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.program-ul06 {
  list-style-type: disc;
  list-style-position: outside;
}
.program-li24 {
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-li25 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text086 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.program-container12 {
  flex: 0 0 auto;
  width: 330px;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.program-text087 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.program-ul07 {
  list-style-type: disc;
  list-style-position: outside;
}
.program-li26 {
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-li27 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text089 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.program-li28 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text090 {
  margin-bottom: 0px;
}
.program-li29 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text091 {
  margin-bottom: 0px;
}
.program-li30 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text092 {
  margin-bottom: 0px;
}
.program-li31 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text093 {
  margin-bottom: 0px;
}
.program-li32 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text094 {
  margin-bottom: 0px;
}
.program-container13 {
  flex: 0 0 auto;
  width: 500px;
  height: auto;
  margin: var(--dl-space-space-twounits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.program-text095 {
  color: rgb(54, 104, 188);
  text-align: center;
}
.program-ul08 {
  list-style-type: disc;
  list-style-position: outside;
}
.program-li33 {
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-li34 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text102 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.program-li35 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text107 {
  margin-bottom: 0px;
}
.program-container14 {
  flex: 0 0 auto;
  width: 500px;
  height: auto;
  margin: var(--dl-space-space-twounits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.program-text113 {
  color: rgb(54, 104, 188);
  text-align: center;
}
.program-ul09 {
  list-style-type: disc;
  list-style-position: outside;
}
.program-li36 {
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-li37 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text120 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.program-li38 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text123 {
  margin-bottom: 0px;
}
.program-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-twounits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.program-text127 {
  color: rgb(54, 104, 188);
  text-align: center;
}
.program-ul10 {
  list-style-type: disc;
  list-style-position: outside;
}
.program-li39 {
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-li40 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text131 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.program-li41 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text132 {
  margin-bottom: 0px;
}
.program-li42 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text133 {
  margin-bottom: 0px;
}
.program-li43 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text134 {
  margin-bottom: 0px;
}
.program-li44 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.program-text135 {
  margin-bottom: 0px;
}
.program-container16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1100px;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.program-container17 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.program-text136 {
  color: var(--dl-color-gray-white);
  width: auto;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.program-text137 {
  color: var(--dl-color-gray-white);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: var(--dl-space-space-halfunit);
}
.program-text138 {
  color: var(--dl-color-gray-white);
  font-size: 25px;
  font-weight: bold;
}
.program-container18 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
}
.program-image {
  width: var(--dl-size-size-large);
  object-fit: cover;
  margin-right: var(--dl-space-space-twounits);
}
.program-image1 {
  width: var(--dl-size-size-large);
  object-fit: cover;
}
@media(max-width: 991px) {
  .program-container01 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .program-container03 {
    max-width: 100%;
  }
  .program-container04 {
    width: 450px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .program-container05 {
    width: 450px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .program-container06 {
    width: 450px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .program-container07 {
    width: 450px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .program-container08 {
    width: 100%;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .program-container09 {
    width: auto;
    flex-wrap: wrap;
  }
  .program-container13 {
    width: 450px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .program-container14 {
    width: 450px;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .program-container15 {
    width: 100%;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .program-ul10 {
    width: 600px;
  }
}
@media(max-width: 479px) {
  .program-container04 {
    width: 100%;
  }
  .program-container05 {
    width: 100%;
  }
  .program-text018 {
    color: rgb(54, 104, 188);
  }
  .program-container06 {
    width: 100%;
  }
  .program-text032 {
    color: rgb(54, 104, 188);
  }
  .program-container07 {
    width: 100%;
  }
  .program-text054 {
    color: rgb(54, 104, 188);
  }
  .program-container10 {
    width: 100%;
  }
  .program-container11 {
    width: 100%;
  }
  .program-container12 {
    width: 100%;
  }
  .program-container13 {
    width: 100%;
  }
  .program-text095 {
    color: rgb(54, 104, 188);
  }
  .program-container14 {
    width: 100%;
  }
  .program-text113 {
    color: rgb(54, 104, 188);
  }
  .program-text127 {
    color: rgb(54, 104, 188);
  }
  .program-ul10 {
    width: 100%;
  }
  .program-container16 {
    flex-wrap: wrap;
  }
  .program-container17 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .program-text136 {
    font-size: 28px;
  }
  .program-text137 {
    width: auto;
    font-size: 18px;
  }
}
