.lnky-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.lnky-breadcumbs {
  flex: 0 0 auto;
  width: 100%;
  height: 25px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.lnky-navlink {
  display: contents;
}
.lnky-icon {
  fill: var(--dl-color-gray-500);
  width: 12px;
  height: 12px;
  text-decoration: none;
}
.lnky-icon2 {
  fill: var(--dl-color-gray-500);
  width: 12px;
  height: 12px;
}
.lnky-navlink1 {
  color: var(--dl-color-gray-500);
  font-size: 12px;
  text-decoration: none;
}
.lnky-text2 {
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.lnky-blog {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: flex-start;
  justify-content: space-between;
}
.lnky-container1 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.lnky-navlink2 {
  display: contents;
}
.lnky-component1 {
  text-decoration: none;
}
.lnky-container2 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.lnky-navlink3 {
  display: contents;
}
.lnky-component2 {
  text-decoration: none;
}
.lnky-container3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lnky-navlink4 {
  display: contents;
}
.lnky-component3 {
  text-decoration: none;
}
@media(max-width: 991px) {
  .lnky-blog {
    flex-direction: column;
  }
  .lnky-container1 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .lnky-container2 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .lnky-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .lnky-blog {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
