.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero {
  width: 100%;
  height: 750px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 60vh;
  align-items: center;
  flex-direction: column;
  padding-bottom: 101px;
  background-size: cover;
  justify-content: center;
  background-color: #D9D9D9;
  background-image: url('http://blur.lepsislatinany.cz/image/DSC_0004.jpg');
  background-position: top;
}
.home-container1 {
  left: auto;
  width: 100%;
  bottom: 0px;
  display: flex;
  overflow: hidden;
  position: absolute;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-text {
  color: var(--dl-color-gray-white);
  width: 377px;
  font-size: 3rem;
  max-width: 25rem;
  text-shadow: 2px 0px 7px #000000;;
}
.home-text03 {
  color: var(--dl-color-gray-white);
  font-size: 1.45rem;
  max-width: 60%;
  margin-top: var(--dl-space-space-halfunit);
  text-shadow: 2px 0px 7px #000000;;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text07 {
  color: var(--dl-color-gray-white);
  font-size: 1.45rem;
  max-width: auto;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: bold;
  text-shadow: 2px 0px 7px #000000;;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-navlink {
  color: var(--dl-color-gray-white);
  font-size: 30px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 400;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.home-navlink:hover {
  transform: scale(1.02);
}
.home-text08 {
  color: var(--dl-color-gray-black);
  font-size: 2rem;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
}
.home-blog {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: flex-start;
  justify-content: space-between;
}
.home-container2 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-navlink1 {
  display: contents;
}
.home-component1 {
  text-decoration: none;
}
.home-container3 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-navlink2 {
  display: contents;
}
.home-component2 {
  text-decoration: none;
}
.home-container4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-navlink3 {
  display: contents;
}
.home-component3 {
  text-decoration: none;
}
.home-navlink4 {
  color: var(--dl-color-gray-white);
  align-self: center;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: bold;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.home-navlink4:hover {
  transform: scale(1.02);
}
.home-text11 {
  font-size: 2rem;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
}
.home-navlink5 {
  color: var(--dl-color-gray-white);
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  transition: 0.3s;
  font-weight: bold;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.home-navlink5:hover {
  transform: scale(1.02);
}
@media(max-width: 991px) {
  .home-text {
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-text03 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-text07 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-navlink {
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-blog {
    flex-direction: column;
  }
  .home-container2 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-container3 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-navlink4 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-navlink5 {
    margin-left: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 767px) {
  .home-hero {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text {
    text-align: left;
  }
  .home-text03 {
    max-width: 100%;
    text-align: left;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .home-text07 {
    max-width: 100%;
    text-align: left;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .home-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container1 {
    margin-left: 0px;
    padding-left: 0px;
  }
  .home-text {
    text-align: left;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-text03 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .home-text07 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .home-navlink {
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-blog {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-navlink4 {
    align-self: center;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-navlink5 {
    align-self: center;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
}
