.one-shot-one-shot {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-self: center;
  background: linear-gradient(0deg, rgba(36,77,151,1) 0%, rgba(237,25,46,1) 100%);;
  margin-top: 0px;
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: 0px;
  justify-content: center;
}
.one-shot-container {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  position: relative;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
}
.one-shot-text {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
}
.one-shot-text3 {
  color: var(--dl-color-gray-white);
  width: auto;
  align-self: flex-start;
  text-align: left;
}
.one-shot-text7 {
  font-weight: 700;
}
.one-shot-image {
  width: 251px;
  height: 175px;
  object-fit: cover;
}
.one-shot-root-class-name {
  max-width: 1300px;
}
.one-shot-root-class-name1 {
  max-width: 1300px;
}
.one-shot-root-class-name2 {
  margin-top: var(--dl-space-space-threeunits);
}
@media(max-width: 991px) {
  .one-shot-container {
    width: 700px;
  }
  .one-shot-image {
    height: 194px;
  }
}
@media(max-width: 767px) {
  .one-shot-one-shot {
    max-width: auto;
  }
  .one-shot-container {
    width: 500px;
  }
  .one-shot-text3 {
    color: var(--dl-color-gray-white);
  }
  .one-shot-text7 {
    font-weight: 700;
  }
}
@media(max-width: 479px) {
  .one-shot-one-shot {
    flex-wrap: wrap;
  }
  .one-shot-container {
    width: 100%;
    align-items: center;
  }
  .one-shot-text {
    text-align: center;
  }
  .one-shot-text3 {
    width: auto;
    text-align: left;
  }
}
