.candidates-mini-candidates-mini {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  max-width: 1100px;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  justify-content: space-between;
}
.candidates-mini-candidate {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 0px;
  flex-direction: row;
  justify-content: flex-start;
}
.candidates-mini-container {
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/zemanova.png');
  background-repeat: no-repeat;
  background-position: center;
}
.candidates-mini-text {
  top: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  right: var(--dl-space-space-halfunit);
  width: 25px;
  height: 25px;
  position: absolute;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 6px;
  border-radius: var(--dl-radius-radius-round);
  background-color: rgb(247, 20, 78);
}
.candidates-mini-container01 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-container02 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-text003 {
  font-weight: bold;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidates-mini-candidate01 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 0px;
  flex-direction: row;
  justify-content: flex-start;
}
.candidates-mini-container03 {
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/lukasova.png');
  background-repeat: no-repeat;
  background-position: center;
}
.candidates-mini-text013 {
  top: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  right: var(--dl-space-space-halfunit);
  width: 25px;
  height: 25px;
  position: absolute;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 6px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #3668bc;
}
.candidates-mini-container04 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-container05 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-text016 {
  font-weight: bold;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidates-mini-text017 {
  font-size: 12px;
}
.candidates-mini-candidate02 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.candidates-mini-container06 {
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/dostalova.png');
  background-repeat: no-repeat;
  background-position: center;
}
.candidates-mini-text027 {
  top: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  right: var(--dl-space-space-halfunit);
  width: 25px;
  height: 25px;
  position: absolute;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 6px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #3668bc;
}
.candidates-mini-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-container08 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-text030 {
  font-weight: bold;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidates-mini-candidate03 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.candidates-mini-container09 {
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/urvalek.png');
  background-repeat: no-repeat;
  background-position: center;
}
.candidates-mini-text040 {
  top: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  right: var(--dl-space-space-halfunit);
  width: 25px;
  height: 25px;
  position: absolute;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 6px;
  border-radius: var(--dl-radius-radius-round);
  background-color: rgb(247, 20, 78);
}
.candidates-mini-container10 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-container11 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-text043 {
  font-weight: bold;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidates-mini-candidate04 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.candidates-mini-container12 {
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/dostalek.png');
  background-repeat: no-repeat;
  background-position: center;
}
.candidates-mini-text051 {
  top: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  right: var(--dl-space-space-halfunit);
  width: 25px;
  height: 25px;
  position: absolute;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 6px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #3668bc;
}
.candidates-mini-container13 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-container14 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-text054 {
  font-weight: bold;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidates-mini-candidate05 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.candidates-mini-container15 {
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/nevim-DSCF0714-2.png');
  background-repeat: no-repeat;
  background-position: center;
}
.candidates-mini-text064 {
  top: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  right: var(--dl-space-space-halfunit);
  width: 25px;
  height: 25px;
  position: absolute;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 6px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #3668bc;
}
.candidates-mini-container16 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-container17 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-text067 {
  font-weight: bold;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidates-mini-candidate06 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.candidates-mini-container18 {
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/young-malek.png');
  background-repeat: no-repeat;
  background-position: center;
}
.candidates-mini-text077 {
  top: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  right: var(--dl-space-space-halfunit);
  width: 25px;
  height: 25px;
  position: absolute;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 6px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #3668bc;
}
.candidates-mini-container19 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-container20 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-text080 {
  font-weight: bold;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidates-mini-candidate07 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.candidates-mini-container21 {
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/neivm-DSCF0291.png');
  background-repeat: no-repeat;
  background-position: center;
}
.candidates-mini-text090 {
  top: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  right: var(--dl-space-space-halfunit);
  width: 25px;
  height: 25px;
  position: absolute;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 6px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #3668bc;
}
.candidates-mini-container22 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-container23 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-text093 {
  font-weight: bold;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidates-mini-candidate08 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.candidates-mini-container24 {
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/stastna.png');
  background-repeat: no-repeat;
  background-position: center;
}
.candidates-mini-text103 {
  top: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  right: var(--dl-space-space-halfunit);
  width: 25px;
  height: 25px;
  position: absolute;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 6px;
  border-radius: var(--dl-radius-radius-round);
  background-color: rgb(247, 20, 78);
}
.candidates-mini-container25 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-container26 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-text106 {
  font-weight: bold;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidates-mini-candidate09 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.candidates-mini-container27 {
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/lukas.png');
  background-repeat: no-repeat;
  background-position: center;
}
.candidates-mini-text116 {
  top: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  right: var(--dl-space-space-halfunit);
  width: 25px;
  height: 25px;
  position: absolute;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 6px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #3668bc;
}
.candidates-mini-container28 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-container29 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-text119 {
  font-weight: bold;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidates-mini-candidate10 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.candidates-mini-container30 {
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/stekla.png');
  background-repeat: no-repeat;
  background-position: center;
}
.candidates-mini-text129 {
  top: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  right: var(--dl-space-space-halfunit);
  width: 25px;
  height: 25px;
  position: absolute;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 6px;
  border-radius: var(--dl-radius-radius-round);
  background-color: rgb(247, 20, 78);
}
.candidates-mini-container31 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-container32 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-text132 {
  font-weight: bold;
  margin-bottom: var(--dl-space-space-halfunit);
}
.candidates-mini-candidate11 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.candidates-mini-container33 {
  width: 100px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.candidates-mini-container34 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.candidates-mini-container35 {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media(max-width: 991px) {
  .candidates-mini-container {
    width: 200px;
    height: 200px;
  }
  .candidates-mini-container03 {
    width: 200px;
    height: 200px;
  }
  .candidates-mini-container06 {
    width: 200px;
    height: 200px;
  }
  .candidates-mini-container09 {
    width: 200px;
    height: 200px;
  }
  .candidates-mini-container12 {
    width: 200px;
    height: 200px;
  }
  .candidates-mini-container15 {
    width: 200px;
    height: 200px;
  }
  .candidates-mini-container18 {
    width: 200px;
    height: 200px;
  }
  .candidates-mini-container21 {
    width: 200px;
    height: 200px;
  }
  .candidates-mini-container24 {
    width: 200px;
    height: 200px;
  }
  .candidates-mini-container27 {
    width: 200px;
    height: 200px;
  }
  .candidates-mini-container30 {
    width: 200px;
    height: 200px;
  }
  .candidates-mini-container33 {
    width: 200px;
    height: 200px;
  }
}
@media(max-width: 767px) {
  .candidates-mini-container {
    width: 240px;
    height: 240px;
  }
  .candidates-mini-container03 {
    width: 240px;
    height: 240px;
  }
  .candidates-mini-container06 {
    width: 240px;
    height: 240px;
  }
  .candidates-mini-container09 {
    width: 240px;
    height: 240px;
  }
  .candidates-mini-container12 {
    width: 240px;
    height: 240px;
  }
  .candidates-mini-container15 {
    width: 240px;
    height: 240px;
  }
  .candidates-mini-container18 {
    width: 240px;
    height: 240px;
  }
  .candidates-mini-container21 {
    width: 240px;
    height: 240px;
  }
  .candidates-mini-container24 {
    width: 240px;
    height: 240px;
  }
  .candidates-mini-container27 {
    width: 240px;
    height: 240px;
  }
  .candidates-mini-container30 {
    width: 240px;
    height: 240px;
  }
  .candidates-mini-container33 {
    width: 240px;
    height: 240px;
  }
}
