.candidates-large-text-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.candidates-large-text-container1 {
  flex: 0 0 auto;
  width: 356px;
  height: 422px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.candidates-large-text-text {
  width: auto;
  align-self: flex-start;
  margin-top: 0px;
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: 0px;
}
.candidates-large-text-image {
  width: 287px;
  height: 110px;
  align-self: flex-end;
  margin-top: 0px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-threeunits);
}
.candidates-large-text-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: center;
}
.candidates-large-text-container3 {
  width: 356px;
  height: 342px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #3668bc;
  border-style: solid;
  background-size: 386px ;
  justify-content: center;
  background-image: url("http://blur.lepsislatinany.cz/image/stastny2.png");
  border-top-width: 0px;
  background-repeat: no-repeat;
  border-left-width: 0px;
  border-right-width: 0px;
  background-position: center;
  border-bottom-width: 4px;
}
.candidates-large-text-text01 {
  left: var(--dl-space-space-unit);
  color: #ffffff;
  width: 50px;
  bottom: var(--dl-space-space-twounits);
  height: 50px;
  position: absolute;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 13px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #3668bc;
}
.candidates-large-text-text04 {
  font-size: 24px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  font-weight: 700;
}
.candidates-large-text-text07 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
}
.candidates-large-text-container4 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: center;
}
.candidates-large-text-container5 {
  width: 356px;
  height: 342px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #ed1e2b;
  border-style: solid;
  border-width: 4px;
  background-size: 346px ;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/stekly.png');
  border-top-width: 0px;
  background-repeat: no-repeat;
  border-left-width: 0px;
  border-right-width: 0px;
  background-position: center;
}
.candidates-large-text-text12 {
  left: var(--dl-space-space-unit);
  color: rgb(255, 255, 255);
  width: 50px;
  bottom: var(--dl-space-space-twounits);
  height: 50px;
  position: absolute;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 13px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #f7144e;
}
.candidates-large-text-text15 {
  font-size: 24px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  font-weight: 700;
}
.candidates-large-text-text18 {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
}

@media(max-width: 479px) {
  .candidates-large-text-container1 {
    display: none;
  }
}
