.candidates-small-2-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.candidates-small-2-container01 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: center;
}
.candidates-small-2-container02 {
  width: 260px;
  height: 260px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #ed1e2b;
  border-style: solid;
  border-width: 1px;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/urbanek.png');
  border-top-width: 0px;
  background-repeat: no-repeat;
  border-left-width: 0px;
  border-right-width: 0px;
  background-position: center;
  border-bottom-width: 4px;
}
.candidates-small-2-text {
  left: var(--dl-space-space-unit);
  color: rgb(255, 255, 255);
  width: 50px;
  bottom: var(--dl-space-space-twounits);
  height: 50px;
  position: absolute;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 13px;
  border-radius: var(--dl-radius-radius-round);
  background-color: rgb(54, 104, 188);
}
.candidates-small-2-text03 {
  font-size: 20px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  font-weight: 700;
}
.candidates-small-2-text06 {
  font-size: 14px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
}
.candidates-small-2-container03 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.candidates-small-2-container04 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: center;
}
.candidates-small-2-container05 {
  width: 260px;
  height: 260px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #3668bc;
  border-style: solid;
  border-width: 1px;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/dalecky.png');
  border-top-width: 0px;
  background-repeat: no-repeat;
  border-left-width: 0px;
  border-right-width: 0px;
  background-position: center;
  border-bottom-width: 4px;
}
.candidates-small-2-text13 {
  left: var(--dl-space-space-unit);
  color: rgb(255, 255, 255);
  width: 50px;
  bottom: var(--dl-space-space-twounits);
  height: 50px;
  position: absolute;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 13px;
  border-radius: var(--dl-radius-radius-round);
  background-color: rgb(247, 20, 78);
}
.candidates-small-2-text16 {
  font-size: 20px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  font-weight: 700;
}
.candidates-small-2-text19 {
  font-size: 14px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
}
.candidates-small-2-container06 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.candidates-small-2-container07 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: center;
}
.candidates-small-2-container08 {
  width: 260px;
  height: 260px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #3668bc;
  border-style: solid;
  border-width: 1px;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/sara.png');
  border-top-width: 0px;
  background-repeat: no-repeat;
  border-left-width: 0px;
  border-right-width: 0px;
  background-position: center;
  border-bottom-width: 4px;
}
.candidates-small-2-text26 {
  left: var(--dl-space-space-unit);
  color: rgb(255, 255, 255);
  width: 50px;
  bottom: var(--dl-space-space-twounits);
  height: 50px;
  position: absolute;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 13px;
  border-radius: var(--dl-radius-radius-round);
  background-color: rgb(54, 104, 188);
}
.candidates-small-2-text29 {
  font-size: 20px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  font-weight: 700;
}
.candidates-small-2-text32 {
  font-size: 14px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
}
.candidates-small-2-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.candidates-small-2-container10 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: center;
}
.candidates-small-2-container11 {
  width: 260px;
  height: 260px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #3668bc;
  border-style: solid;
  border-width: 1px;
  background-size: cover;
  justify-content: center;
  background-image: url('http://blur.lepsislatinany.cz/image/DSCF0354.png');
  border-top-width: 0px;
  background-repeat: no-repeat;
  border-left-width: 0px;
  border-right-width: 0px;
  background-position: center;
  border-bottom-width: 4px;
}
.candidates-small-2-text39 {
  left: var(--dl-space-space-unit);
  color: rgb(255, 255, 255);
  width: 50px;
  bottom: var(--dl-space-space-twounits);
  height: 50px;
  position: absolute;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: 13px;
  border-radius: var(--dl-radius-radius-round);
  background-color: rgb(54, 104, 188);
}
.candidates-small-2-text42 {
  font-size: 20px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  font-weight: 700;
}
.candidates-small-2-text45 {
  font-size: 14px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
}

@media(max-width: 991px) {
  .candidates-small-2-container02 {
    width: 200px;
    height: 200px;
  }
  .candidates-small-2-container05 {
    width: 200px;
    height: 200px;
  }
  .candidates-small-2-container08 {
    width: 200px;
    height: 200px;
  }
  .candidates-small-2-container11 {
    width: 200px;
    height: 200px;
  }
}
@media(max-width: 767px) {
  .candidates-small-2-container02 {
    width: 240px;
    height: 240px;
  }
  .candidates-small-2-text {
    padding-top: 15px;
  }
  .candidates-small-2-container05 {
    width: 240px;
    height: 240px;
  }
  .candidates-small-2-container08 {
    width: 240px;
    height: 240px;
  }
  .candidates-small-2-container11 {
    width: 240px;
    height: 240px;
  }
}
@media(max-width: 479px) {
  .candidates-small-2-container02 {
    width: 356px;
    height: 356px;
  }
  .candidates-small-2-container05 {
    width: 356px;
    height: 356px;
  }
  .candidates-small-2-container08 {
    width: 356px;
    height: 356px;
  }
  .candidates-small-2-container11 {
    width: 356px;
    height: 356px;
  }
}
