.lnek-jak-bude-poplatek-za-odpady-pt-rok-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-breadcumbs {
  flex: 0 0 auto;
  width: 100%;
  height: 25px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-navlink {
  display: contents;
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-icon {
  fill: var(--dl-color-gray-500);
  width: 12px;
  height: 12px;
  text-decoration: none;
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-icon2 {
  fill: var(--dl-color-gray-500);
  width: 12px;
  height: 12px;
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-navlink1 {
  color: var(--dl-color-gray-500);
  font-size: 12px;
  text-decoration: none;
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-icon4 {
  fill: var(--dl-color-gray-500);
  width: 12px;
  height: 12px;
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-navlink2 {
  color: var(--dl-color-gray-500);
  font-size: 12px;
  text-decoration:    none;
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-text02 {
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: column;
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-text03 {
  margin-bottom: var(--dl-space-space-unit);
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-container2 {
  width: auto;
  display: flex;
  align-self: flex-end;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: space-between;
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-profile {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-image {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-text15 {
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
}
.lnek-jak-bude-poplatek-za-odpady-pt-rok-text16 {
  color: var(--dl-color-gray-700);
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
}
