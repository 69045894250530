.invitation-invitation {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  border-image: linear-gradient(to bottom, #244d97, #f7144e) 1;
  border-style: solid;
  border-width: 8px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.invitation-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.invitation-image {
  width: 100px;
  object-fit: cover;
  margin-left: 0px;
}
.invitation-image1 {
  width: 100px;
  object-fit: cover;
  margin-left: var(--dl-space-space-unit);
}
.invitation-text {
  font-size: 40px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 700;
}
.invitation-text1 {
  color: rgb(114, 116, 121);
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 700;
}
.invitation-text2 {
  color: #ed192e;
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 700;
}



@media(max-width: 991px) {
  .invitation-text {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .invitation-text {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .invitation-text {
    text-align: center;
  }
  .invitation-text1 {
    text-align: center;
  }
}
